<template>
  <div class="card" :class="withbg ? 'bg' : ''">
    <div class="card-header">
      {{ title }}
    </div>
    <template v-if="withbg">
    <div class="card-body">
      <div class="card-body-main">
        <slot></slot>
      </div>
    </div>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
export default {
  props: ["title", 'withbg'],
};
</script>

<style lang="less" scoped>
.card {
  width: 1472px;
  margin: 30px auto;
  &.bg {
    width: 100%;
    margin: 0;

    .card-body {
      background: url('../../assets/bg-show.png') no-repeat center center;
      // min-height: 414px;

      .card-body-main {
        width: 1472px;
        margin: 0px auto;
      }
    }

  }
  .card-header {
    text-align: center;
    font-size: 32px;
    height: 54px;
    margin-bottom: 30px;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 50px;
      height: 4px;
      bottom: 0px;
      left: calc(50% - 25px);
      background: #DADADA;
    }
  }
}
</style>
