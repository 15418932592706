<template>
  <div class="bm" id="allmap"></div>
</template>
<script>
import { BMPGL } from "@/util/bmpgl.js";
export default {
  props: ["projectList"],
  data() {
    return {
      ak: window.global.bmKey,
      lon: window.global.view.center[0],
      lat: window.global.view.center[1],
      zoom: window.global.view.zoom,
      icon: require("../../../../assets/red-icon.png"),
      progressList: [],
      YTZYList: [],
      doneList: [],
      map: null,
      BMapGL: null,
    };
  },
  watch: {
    projectList: function () {
      // Prop发生变化后重新调用
      this.addPoint();
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      // 传入密钥获取地图回调。
      BMPGL(this.ak)
        .then((BMapGL) => {
          // 创建地图实例
          let map = new BMapGL.Map("allmap");
          // 创建点坐标 axios => res 获取的初始化定位坐标
          let point = new BMapGL.Point(this.lon, this.lat);
          // 初始化地图，设置中心点坐标和地图级别
          map.centerAndZoom(point, this.zoom);
          //开启鼠标滚轮缩放
          map.enableScrollWheelZoom(true);
          // 添加缩放控件
          var zoomCtrl = new BMapGL.ZoomControl();
          map.addControl(zoomCtrl);
          // 保存数据
          this.BMapGL = BMapGL;
          this.map = map;
          this.addPoint();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addPoint() {
      // 判断列表是否有值
      if (!this.projectList) {
        return;
      } else {
        this.getInfo(this.projectList, "地址", this.doneList);
        this.addMarker(this.doneList, this.icon);
      }
      // 判断地图是否已加载
      if (!this.map) {
        return;
      }
    },
    getInfo(data, type, newData) {
      data.forEach((item) => {
        newData.push({
          pName: item.name,
          //company: item.jsdw,
          pId: item.id,
          status: type,
          lon: item.lot,
          lat: item.lat,
        });
      });
    },
    addMarker(data, icon) {
      const BMapGL = this.BMapGL;
      if (BMapGL) {
        let projectIcon = new BMapGL.Icon(
          //打点图标
          icon,
          new BMapGL.Size(21, 26)
        );
        //设置图标大小
        projectIcon.setImageSize(new BMapGL.Size(21, 26));
        data.forEach((ele) => {
          let pt = new BMapGL.Point(ele.lon, ele.lat);
          let marker = new BMapGL.Marker(pt, { icon: projectIcon });
          this.map.addOverlay(marker);
          //设置弹出框
          let opts = {
            width: 220, // 信息窗口宽度
            height: 30, // 信息窗口高度
            title:
              '<div style="font-size:16px;color:#00aeee;font-weight: 600; padding:4px">' +
              '<span style="color:#FFB005; font-size:8px !important; padding:2px 4px; border-radius: 3px; margin-right:10px; border:1px solid #FFB005; background:rgba(255, 176, 5, 0.1)">' +
              ele.status +
              "</span>" +
              ele.pName +
              "</div>", // 信息窗口标题
          };

          //设置文本标题显示
          var label = new BMapGL.Label(ele.pName, {
            offset: new BMapGL.Size(15, -15),
          });

          label.setStyle({
            border: "1px solid #DCDCDC",
            borderRadius: "2px",
            padding: "5px",
            color: "#666666",
            backgroundColor: "#FFFFFF",
            opacity: 1,
            fontSize:"14px",
            fontFamily:"Source Han Sans CN"
          });
          marker.setLabel(label);
        });
      }
    },
    back() {
      this.initMap();
    },
  },
};
</script>
<style lang="less" scoped>
.bm {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.boxTitle {
  font-size: 16px;
  color: #00aeee;
  font-weight: 600;
}
/deep/ .anchorBL {
  display: none;
}

/deep/ .BMap_bubble_buttons {
  display: none;
}
</style>
