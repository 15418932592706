const componentType = {
  Input: "input", // 单行输入框
  Date: "date", // 日期时间选择框
  RangeDate: "rangeDate", // 日期时间范围选择
  Select: "select", // 下拉选择框
  AsyncSelect: "asyncSelect", // 异步下拉选择框
  AsyncTreeSelect: "asyncTreeSelect", // 异步下拉树选择框
  RadioGroup: "radioGroup", // 单选组合
  Text: "text",//图标(图片)文字组合
}

export default componentType
