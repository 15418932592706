import FormItem from "./FormItem"

/**
 * 根据数据生成form、rules对象
 * @param {Array<ComponentType>} data
 * @returns {form, rules}
 */
export function createFormAndRules(data) {
  if (!Array.isArray(data)) {
    return
  }

  let form = {}
  let rules = {}
  let formItems = []

  for (let i = 0; i < data.length; i++) {
    const formItem = data[i]

    if (!formItem.name) {
      continue
    }
    // debugger;
    let item = new FormItem(formItem)
    form[formItem.name] = item._value
    rules[formItem.name] = item._rules
    formItems.push(item)
  }
  return { form, rules, formItems }
}
